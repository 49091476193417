// const createSectionNav = (root) => {
//   const nav = root.querySelector("#js-list-tab");
//   const main = root.querySelector("#js-cases-wrapper");
//   let currentActiveStep = nav.querySelector(".active-list-item");

//   nav.addEventListener("click", ({ target }) => {
//     const { section } = target.closest(".js-list-item").dataset;
//     if (!section) return;
//     main.querySelector(`[data-section='${section}']`).scrollIntoView({
//       behavior: "smooth",
//     });
//   });

//   main.querySelectorAll(".l-case-item__category").forEach((s) => {
//     new IntersectionObserver(
//       (entries) => {
//         if (entries[0].isIntersecting) {
//           const { section } = s.dataset;
//           if (!section) return;
//           currentActiveStep.classList.remove("active-list-item");
//           const newActiveStep = nav.querySelector(
//             `[data-section='${section}']`
//           );
//           newActiveStep.classList.add("active-list-item");
//           currentActiveStep = newActiveStep;
//         }
//       },
//       {
//         // root: document.querySelector( '.title-pad' ),
//         threshold: 0.01,
//         // rootMargin: '0px',
//       }
//     ).observe(s);
//   });
// };
// createSectionNav(document.querySelector("#case-section"));

export function casesTabs() {
  // const tabs = document.querySelector(".q-news-body");
  const tabBtn = document.querySelectorAll(".js-list-item");
  const tabContent = document.querySelectorAll(".l-case-item__category");


  if(document.querySelector('#js-cases-wrapper')) {
    tabBtn.forEach((el) => {
      el.addEventListener("click", openTabs);
      // el[0].click()
    });
  }


  function openTabs(el) {
    var btnTarget = el.currentTarget;
    var tab = btnTarget.dataset.caseBtn;
    tabContent.forEach(function (el) {
      el.classList.remove("tab-content-active");
    });
    tabBtn.forEach(function (el) {
      el.classList.remove("active-list-item");
    });
    document.querySelector("#" + tab).classList.add("tab-content-active");
    btnTarget.classList.add("active-list-item");
  }
}
