import "./modules/partners-swiper";

import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;
import 'jquery-mask-plugin';

import { casesTabs } from "./modules/scroll-case";
import { servicesTab } from "./modules/services-tab";
import { menuRegisterClick } from "./modules/mobile-menu";
import { registerListTabChanger } from "./modules/tabbed-list";
import { registerFormCallbacks } from "./modules/contact-form";
import { animateNumbers } from "./modules/number-anim";

// import
servicesTab();
casesTabs();
menuRegisterClick();
registerListTabChanger();
registerFormCallbacks();
animateNumbers();

