import { currentMode, WindowSizeEnum } from "./window-size-observer";

export function registerListTabChanger() {
  function getElemFromHref(href) {
    if (href.slice(-1) === "/") {
      const hrefs = href.split("/");
      return hrefs[hrefs.length - 2];
    }
    else {
      const hrefs = href.split("#");
      return hrefs[hrefs.length - 1];
    }
  }

  window.addEventListener("scroll",function(){
    window.lastScrollTime = new Date().getTime();
  });
  function isScrolling() {
      return window.lastScrollTime && new Date().getTime() < window.lastScrollTime + 40;
  }

  document.querySelectorAll(".l-list-types").forEach(function (elem) {
    [...elem.getElementsByTagName("a")].forEach(function (link) {

      if (elem.dataset.disabled !== "true") {

        link.addEventListener("click", function (event) {
          if (elem.dataset.preventDefault === "true") {
            event.preventDefault();
          }

          const prevActive = elem.getElementsByClassName("l-active")[0];
          const newElemString = getElemFromHref(event.target.href);
          const prevElemString = getElemFromHref(prevActive.href);
          const curContent = document.getElementById(prevElemString);
          const newContent = document.getElementById(newElemString);

          prevActive.classList.remove("l-active");
          event.target.classList.add("l-active");

          curContent.classList.remove("l-active");
          newContent.classList.add("l-active");


          if (elem.dataset.preventDefault === "true" && currentMode === WindowSizeEnum.COMPUTER) { 
            let scrollPos = newContent.getBoundingClientRect().top + window.scrollY - 180;

            setTimeout(function(){
              window.scrollTo(0,  scrollPos);
            }, 5);
            setTimeout(function(){
              if(!isScrolling()){
                window.scrollTo(0, scrollPos);
              }
            }, 300);
          }
        });
      }
    });
  });
}