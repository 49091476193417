

export function registerFormCallbacks() {

  const form = document.querySelector('.l-request-form');
  if (form) {

    //js tel-mask
    window.$('#phoneNumber').mask("+9 (999) 999-99-99");

    document.querySelectorAll(".l-task-group").forEach(function (elem) {
      if (!elem.id) {
        [...elem.getElementsByTagName('input')].forEach(function (input) {

          input.addEventListener('change', function () {
            // Disaable all task groups with id
            document.querySelectorAll(".l-task-group").forEach(function (oldElem) {
              if (oldElem.id) {
                oldElem.classList.remove('l-active');
                [...oldElem.getElementsByTagName('input')].forEach(function (openedInput) {
                  openedInput.checked = false;
                  openedInput.disabled = true;
                });
              }
            });

            const toOpen = 'form-' + input.id + '-0';
            const toOpenElem = document.getElementById(toOpen);

            toOpenElem.classList.add('l-active');
            [...toOpenElem.getElementsByTagName('input')].forEach(function (openedInput) {
              openedInput.disabled = false;
            });
          });
        });

      } else {

        [...elem.getElementsByTagName('input')].forEach(function (input) {

          input.addEventListener('change', function () {
            const currentTask = parseInt(elem.id.slice(-1));
            const toOpen = elem.id.slice(0, -1) + (currentTask + 1).toString();
            const toOpenElem = document.getElementById(toOpen);

            if (toOpenElem) {
              toOpenElem.classList.add('l-active');
              [...toOpenElem.getElementsByTagName('input')].forEach(function (openedInput) {
                openedInput.disabled = false;
              });
            }
          });
        });
      }
    });


    document.getElementById('form-upload')
      .addEventListener('change', function (event) {
        const fileText = document.getElementById('form-file-text');
        const files = event.target.files || event.dataTransfer.files;

        fileText.innerText = [...files].reduce(function (shared, file) {
          return shared === "" ? `${file.name}` : `${shared}, ${file.name}`;
        }, "");
      });
  }
};