
export function animateNumbers(){
  //numbers on section about
  let firstNumAnim = true;
  const scrollImations = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && firstNumAnim) {
        
        $('.l-anim-num').each(function () {
          var $this = $(this);
          jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
            duration: 2000,
            easing: 'swing',
            step: function () {
              $this.text(Math.ceil(this.Counter));
            }
          });
        });
  
        firstNumAnim = false;
      }
    });
  }
  const observer = new IntersectionObserver(scrollImations);
  const counterSection = document.querySelector('.l-about-card');
  if(counterSection){
    observer.observe(counterSection);
  }
}