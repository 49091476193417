
export function servicesTab() {
  const tabBtnServices = document.querySelectorAll(".js-list-item-1");
  const tabContentServices = document.querySelectorAll(".l-services-panel");

  tabBtnServices.forEach((el) => {
    el.addEventListener("click", openTabs);
  });
  function openTabs(el) {
    var btnTarget = el.currentTarget;
    var tab = btnTarget.dataset.servicesBtn;
    tabContentServices.forEach(function (el) {
      el.classList.remove("active-panel");
    });
    tabBtnServices.forEach(function (el) {
      el.classList.remove("active-list-item");
    });
    document.querySelector("#" + tab).classList.add("active-panel");
    btnTarget.classList.add("active-list-item");
  }
}
