
document.querySelectorAll('.partners-wrapper').forEach(n => {

  const top = new Swiper(n.querySelector('.partners-top'), {
    spaceBetween: 20,
    centeredSlides: true,
    speed: 6000,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    loop: true,
    slidesPerView: "auto",
    loopedSlides: 20,
    allowTouchMove: true,
    disableOnInteraction: true,
  });

  const middle = new Swiper(n.querySelector('.partners-middle'), {
    spaceBetween: 20,
    centeredSlides: true,
    speed: 6000,
    autoplay: {
      delay: 1,
      reverseDirection: true,
      disableOnInteraction: false,
    },
    loop: true,
    slidesPerView: "auto",
    loopedSlides: 20,
    allowTouchMove: true,
    disableOnInteraction: true,
  });

  const lower = new Swiper(n.querySelector('.partners-lower'), {
    spaceBetween: 20,
    centeredSlides: true,
    speed: 6000,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    loop: true,
    slidesPerView: "auto",
    loopedSlides: 20,
    allowTouchMove: true,
    disableOnInteraction: true,
  });

  // slider.controller.control = thumbs;
  // thumbs.controller.control = slider;
});